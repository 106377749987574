import logo from './logo.svg';
import './App.css';
import Register from './components/Register';
import React from 'react';
import axios from 'axios';
import Login from './components/Login';
import Home from './components/Home';
import DocView from './components/DocView';
import LessonList from './components/LessonList';
import backIcon from './assets/images/back_icon.svg';
import CourseCheck from './components/CourseCheck';
import Quiz from './components/Quiz';
import MakeQuiz from './components/MakeQuiz';
import Profile from './components/Profile';
import Notification from './components/Notification';
import AOS from 'aos';
import "aos/dist/aos.css";
import WebView from './components/WebView';
import About from './components/About';


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentView: "Login",
      currentUser: localStorage.getItem('currentUser'),
     
    }

this.setHomeView = this.setHomeView.bind(this);
    this.setRegView = this.setRegView.bind(this);
    this.setLogView = this.setLogView.bind(this);
    this.setDocView = this.setDocView.bind(this);
    this.setLessonListView = this.setLessonListView.bind(this);
    this.setCourseCheckView = this.setCourseCheckView.bind(this);
    this.setQuizView = this.setQuizView.bind(this);
    this.setQuizStartView = this.setQuizStartView.bind(this);
    this.setProfileView = this.setProfileView.bind(this);
    this.setNotificationView =  this.setNotificationView.bind(this);
    console.log("current user " + this.state.currentUser)
    this.setWebView = this.setWebView.bind(this);
    this.setAboutView = this.setAboutView.bind(this);
  }

  setHomeView() {
    this.setState({ currentView: "Home" })
  }

  setRegView()
  {
    this.setState({currentView:"Reg"})
  }
  setLogView()
  {
    this.setState({currentView:"Log"})
  }

  setDocView()
  {
    this.setState({currentView:"Doc"})
   
  }

  setProfileView()
  {
    this.setState({currentView:"Pro"})
  }

  setLessonListView()
  {
    this.setState({currentView:"LesList"})
  }

  setCourseCheckView()
  {
    this.setState({currentView:"CCheck"})
  }

  setWebView()
  {
    this.setState({currentView:"WebView"})
  }
  setQuizView()
  {
    this.setState({currentView:"QuizCreate"})
  }

  setQuizStartView()
  {
    this.setState({currentView:"Quiz"})
  }

  setNotificationView()
  {
    this.setState({currentView:"Notification"})
  }

  setAboutView()
  {
    this.setState({currentView:"About"})
  }
  componentDidMount()
  {
    AOS.init();
  }


  render() {

    if (this.state.currentView == "About") {
      return (

        <div ><About setHomeView={this.setHomeView}/></div>
      );
    }

    if (this.state.currentView == "Notification") {
      return (

        <div ><Notification setHomeView={this.setHomeView}/></div>
      );
    }

    if (this.state.currentView == "WebView") {
      return (

        <div ><WebView /></div>
      );
    }
    if (this.state.currentView == "Pro") {
      return (

        <div ><Profile setLoginView={this.setLogView} setHomeView={this.setHomeView}/></div>
      );
    }
    if (this.state.currentView == "QuizCreate") {
      return (

        <div ><MakeQuiz loadQuizView={this.setQuizStartView} data-aos="zoom-in-down" setHomeView={this.setHomeView}/></div>
      );
    }
    if (this.state.currentView == "Quiz") {
      return (

        <div ><Quiz goBack={this.setHomeView}/></div>
      );
    }
    if (this.state.currentView == "CCheck") {
      return (

        <div ><CourseCheck setHomeView={this.setHomeView}/></div>
      );
    }
    if (this.state.currentView == "Doc") {
      return (

        <div ><DocView goBack={this.setLessonListView}/></div>
      );
    }

    if (this.state.currentView == "Log") {
      return (

        <div ><Login setHomeView={this.setHomeView} setRegView={this.setRegView}/></div>
      );
    }

    if (this.state.currentView == "LesList") {
      return (

        <div ><LessonList setDocView={this.setDocView} goBack={this.setHomeView}/></div>
      );
    }
    if (this.state.currentView == "Home") {
      return (<div ><Home setAboutView={this.setAboutView} setLogView={this.setLogView} currentUser={this.state.currentUser} setNotificationView={this.setNotificationView} setLessonListView={this.setLessonListView} setCourseCheckView={this.setCourseCheckView} setQuizView={this.setQuizView} setProfileView={this.setProfileView}/></div>
      );
    }
    if (this.state.currentUser != null) {
      return (<div ><Home setAboutView={this.setAboutView} setLogView={this.setLogView}  currentUser={this.state.currentUser} setNotificationView={this.setNotificationView} setLessonListView={this.setLessonListView} setCourseCheckView={this.setCourseCheckView} setQuizView={this.setQuizView} setProfileView={this.setProfileView}/></div>
      );

    }

    if (this.state.currentView == "Reg") {
      return (

        <div ><Register setLogView={this.setLogView} /></div>
      );
    }

  
   

    if (this.state.currentView == "Login") {
      return (

        <div ><Login setHomeView={this.setHomeView} setRegView={this.setRegView}/></div>
      );
    }

  


  }
}


export default App;
