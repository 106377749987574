import React from 'react';
import '../App.css';
import backIcon from '../assets/images/back_icon.svg';
import axios from 'axios';
import AOS from 'aos';
import "aos/dist/aos.css";
import profileIcon from '../assets/images/profile.svg';



class Alert extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
     
        }

    }


    render() {
       
        return ( <div className="alert-red" style={{display:this.props.visibility, backgroundColor:this.props.alertColor}}><p className="alert-text" >{this.props.errorAlertMsg}</p></div>)


    }
}

export default Alert;
