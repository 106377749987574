import React from 'react';
import '../App.css';
import backIcon from '../assets/images/back_icon.svg';
import axios from 'axios';
import AOS from 'aos';
import "aos/dist/aos.css";
import profileIcon from '../assets/images/profile.svg';
import Modal from './Modal';
import Alert from './Alert';



class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: localStorage.getItem('currentUser'),
            oldPassword: "",
            newPassword: "",
            confirmNewPassword: "",
            deletePassword: "",
            errorModalVisibility: "none",
            errorAlert: "none",
            errorAlertMsg: "",
            alertColor: "",
            deleteModalVisibility: "none"
        }
        this.goBack = this.goBack.bind(this);
        this.handleData = this.handleData.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);
        this.onPasswordChangeSuccess = this.onPasswordChangeSuccess.bind(this);
        this.showModal = this.showModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.changePasswordCheck = this.changePasswordCheck.bind(this);
        this.deleteAccountCheck = this.deleteAccountCheck.bind(this);
        this.handleDeleteData = this.handleDeleteData.bind(this);
        this.onDeleteSuccess = this.onDeleteSuccess.bind(this);
        this.deleteAccount = this.deleteAccount.bind(this);


    }

    goBack() {
        this.props.goBack();
    }

    handleChangePassword(event) {
        event.preventDefault();
        let formData = new FormData();
        formData.append("email", this.state.email);
        formData.append("password", this.state.oldPassword);
        formData.append("newPassword", this.state.newPassword)
        const url = "https://noted.venusha.com/changePassword.php";

        axios.post(url, formData)
            .then(res => this.onPasswordChangeSuccess(res))
            .catch(err => console.log(err));

    }

    handleDeleteAccount() {

    }
    onPasswordChangeSuccess(res) {
        if (res.data == "1") {
            this.setState({ errorModalVisibility: "none" })
            this.setState({ errorAlert: "flex" })
            this.setState({ errorAlertMsg: "Password Changed Succefully" })
            this.setState({ alertColor: "#03ad14" })
            localStorage.clear();
            this.props.setLoginView();

        }
        else {
            this.setState({ errorModalVisibility: "none" })
            this.setState({ errorAlert: "flex" })
            this.setState({ errorAlertMsg: "Oops! Something went wrong" })
            this.setState({ alertColor: "#ff3737" })
        }

    }


    handleData(event) {
        this.setState({ [event.target.name]: event.target.value });
        this.setState({ errorAlert: "none" })

    }

    showModal() {
        this.setState({ errorModalVisibility: "flex" })
    }

    closeModal() {
        this.setState({ errorModalVisibility: "none" })
        this.setState({ deleteModalVisibility: "none" })
    }

    postivieActionTest() {
        alert();
    }

    changePasswordCheck() {
        if (this.state.oldPassword != "" && this.state.newPassword == this.state.confirmNewPassword) {
            this.showModal();

        }

        else {
            this.setState({ errorAlert: "flex" });
            this.setState({ errorAlertMsg: "Oops! There is a problem with your inputs. " });
            this.setState({ alertColor: "#ff3737" });
        }
    }

    handleDeleteData(event) {
        this.setState({ [event.target.name]: event.target.value });
        this.setState({ errorAlert: "none" })

    }

    deleteAccountCheck() {
        if (this.state.deletePassword != "") {
            this.setState({ deleteModalVisibility: "flex" })
        }
        else {
            this.setState({ errorAlert: "flex" });
            this.setState({ errorAlertMsg: "Oops! Enter your Password" });
            this.setState({ alertColor: "#ff3737" });
        }

    }

    deleteAccount(event) {
        event.preventDefault();
        let formData = new FormData();
        formData.append("email", this.state.email);
        formData.append("password", this.state.deletePassword);
        // formData.append("newPassword", this.state.newPassword)
        const url = "https://noted.venusha.com/deleteAccount.php";

        axios.post(url, formData)
            .then(res => this.onDeleteSuccess(res))
            .catch(err => console.log(err));

    }

    onDeleteSuccess(res) {
        if (res.data == "1") {

            localStorage.clear();
            this.props.setLoginView();

        }
        else {
            this.setState({ errorModalVisibility: "none" })
            this.setState({ errorAlert: "flex" })
            this.setState({ errorAlertMsg: "Oops! Something went wrong" })
            this.setState({ alertColor: "#ff3737" })
            this.closeModal();

        }
    }

    goBack() {
        this.props.setHomeView();
    }




    render() {




        return (
            <div style={{ width: "100vw",backgroundColor: "#CEECFE", height:"110vh"}} >

                    <div className="modal" style={{ display: this.state.errorModalVisibility }}>
                        <h4>Are Sure You want to change your existing password? {this.props.title}</h4>
                        <button onClick={this.handleChangePassword} className="btn-primary">Yes</button>
                        <button onClick={this.closeModal} className="btn-secondary mt">No</button>

                    </div>

                    <div className="modal" style={{ display: this.state.deleteModalVisibility }}>
                        <h4>Are Sure You want to delete your account? This action cannot be undone. {this.props.title}</h4>
                        <button onClick={this.deleteAccount} className="btn-primary" style={{ backgroundColor: "#fc2403" }}>Yes</button>
                        <button onClick={this.closeModal} className="btn-secondary mt">No</button>

                    </div>

                <div className="fixed-top-bar"  style={{zIndex:"1000", backgroundColor:"#CEECFE"}}  >
                    <div className="top-bar">
                        <img src={backIcon} onClick={this.goBack}></img>
                        <h4>My Account</h4>
                    </div>

                </div>
                <div className="profile-container"   style={{paddingTop:"6em", paddginBottom:"1em"}}>
                <Alert visibility={this.state.errorAlert} alertColor={this.state.alertColor} errorAlertMsg={this.state.errorAlertMsg} />
                    <img src={profileIcon} style={{width:"5em"}} ></img>
                    <h4 style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", maxWidth: "90vw", margin:"5px" }}>{localStorage.getItem('currentUser')}</h4>
                   
                    <p >Change Password</p>

                    <input type="password" className="input-text" value={this.state.oldPassword} onChange={this.handleData} name="oldPassword" id="oldPassword" placeholder="Current Password" />
                    <input type="password" className="input-text" value={this.state.newPassword} onChange={this.handleData} name="newPassword" id="newPassword" placeholder="New Password" />
                    <input type="password" className="input-text" value={this.state.confirmNewPassword} onChange={this.handleData} name="confirmNewPassword" id="confirmNewPassword" placeholder="Confirm New Password" />


                    <button className="btn-primary mt mt" onClick={this.changePasswordCheck}>Change Password</button>

                    <p style={{ marginTop: "40px" }}>Delete Account </p>

                    <input type="password" className="input-text mt" value={this.state.deletePassword} onChange={this.handleDeleteData} name="deletePassword" id="deletePassword" placeholder="Password" />
                    <button className="btn-primary mt" style={{ backgroundColor: "#fc2403", marginBottom:"5px" }} onClick={this.deleteAccountCheck}>Delete Account</button>
                    
                </div>
            </div>)


    }
}

export default Profile;
