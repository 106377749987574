import React from 'react';
import '../App.css';
import backIcon from '../assets/images/back_icon.svg';


class DocView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentUser: "",
            path:localStorage.getItem('currentDocPath'),
            title:localStorage.getItem('currentDocTitle'),
            pathPrefix:"https://docs.google.com/viewer?embedded=true&url="
           
        }
        this.goBack = this.goBack.bind(this)
    }
 goBack()
 {
     this.props.goBack();
 }


  
    render() {
        return (
           <div>
                <div className="fixed-top-bar" style={{backgroundColor:"#ffffff"}}>
                <div className="top-bar">
                    <img src={backIcon} onClick={this.goBack}></img>
                    <h4>{this.state.title.substring(0,35)}...</h4>

                </div>
  
                </div>
             <iframe src={this.state.pathPrefix+this.state.path}  style={{height:"100vh", width:"100vw",marginTop:"90px"}}/>
        </div>
        )
    }
}

export default DocView;
