import react from 'react';
import '../App.css';
import axios from 'axios';
import doodle from '../assets/images/reg_img.svg';
import Alert from './Alert';



class Register extends react.Component {
    constructor(props) {
        super(props)
        this.state = {
            email: "",
            password: "",
            confirm_password: "",
            agreed:false,
            errorAlert: "none",
            errorAlertMsg: "",
            alertColor: ""
        }

        this.handleData = this.handleData.bind(this);
        this.handleReg = this.handleReg.bind(this);
        this.loadLogin = this.loadLogin.bind(this);
        this.handleCheckBox = this.handleCheckBox.bind(this);
    }

    handleData(event) {
        this.setState({ [event.target.name]: event.target.value });
        console.log(this.state.email);
    }

    handleReg(event) {
        if(this.state.agreed==true)
        {
            if(this.state.password==this.state.confirm_password)
            {
                var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                if(regex.test(this.state.email)==true)
                {
                    event.preventDefault();
                    let formData = new FormData();
                    formData.append("email", this.state.email);
                    formData.append("password", this.state.password);
                    const url = "https://noted.venusha.com/RegUser.php";
                    axios.post(url, formData)
                        .then(res => this.onRegSuccess(res))
                        .catch(err => console.log(err));
                }

                else
                {
                    this.setState({errorAlert:"flex"});
                    this.setState({errorAlertMsg:"Invalid Email"});
                    this.setState({alertColor:"#ff3737"})
                }
              
            }
            else
            {
            this.setState({errorAlert:"flex"});
            this.setState({errorAlertMsg:"Passwords does not match"});
            this.setState({alertColor:"#ff3737"})
            }
     
        }
        else
        {
           
            this.setState({errorAlert:"flex"});
            this.setState({errorAlertMsg:"You must agree to our Privacy Policy, Terms, Conditions."});
            this.setState({alertColor:"#ff3737"})
        }
    }

    onRegSuccess(res)
    {
        console.log(res.data);
        this.props.setLogView();
        localStorage.setItem('currentUser', this.state.email);
        
        this.setState({errorAlert:"flex"});
        this.setState({errorAlertMsg:"Successfully Signed Up. You can Login Now."});
        this.setState({alertColor:"#ff3737"})
    }
  
    loadLogin()
    {
        this.props.setLogView();
    }
    handleCheckBox()
    {
        if(this.state.agreed==false)
        {
            this.setState({agreed:true})
        }

        else if(this.state.agreed==true)
        {

            this.setState({agreed:false})
        }
        
    }


    render() {
        return (
            <div className="bg-light-blue page-container">
                <h1 className="header-text mt-20">Ready to Get Started?</h1>
                <div >
                    <img src={doodle} className="header-image" />
                </div>

                <h3 className="sub-head">Sign Up with ICT Guru</h3>
                <Alert visibility={this.state.errorAlert} alertColor={this.state.alertColor} errorAlertMsg={this.state.errorAlertMsg} />
                <div className="input-container">
                    <input type="text" className="input-text" value={this.state.email} onChange={this.handleData} name="email" id="email" placeholder="Email" />
                    <input type="password" className="input-text" value={this.state.password} onChange={this.handleData} name="password" id="password" placeholder="Password" />
                    <input type="password" className="input-text" value={this.state.confirm_password} onChange={this.handleData} name="confirm_password" id="confirm_password" placeholder="Confirm Password" />
                </div>
               
                <p className="note-text"> <input type="checkbox" className="check" onChange={this.handleCheckBox}   checked={this.state.agreed}/>I am agreeing to the <strong>Privacy Policy</strong> and <strong> Terms and Conditions </strong> of use.</p>
                <button className="btn-primary" onClick={this.handleReg}>Sign Up</button>
                <p className="note-text mt-20">Already have an account?</p>
                <button className="btn-secondary" onClick={this.loadLogin}>Login</button>
            </div>
        )
    }

}

export default Register;

