import React from 'react';
import '../App.css';
import backIcon from '../assets/images/back_icon.svg';
import axios from 'axios';
import AOS from 'aos';
import "aos/dist/aos.css";


class WebView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentUser: "",
         

        }
        this.goBack = this.goBack.bind(this)
    }

    componentDidMount() {
        AOS.init();
    }
    goBack() {
        this.props.goBack();
    }



    render() {
        return (
            <div style={{ display: this.props.visibility }} scroll="no" >
                <div className="fixed-top-bar" style={{ backgroundColor: "#ffffff" }}>
                    <div className="top-bar">
                        <img src={backIcon} onClick={this.goBack}></img>
                        <h4></h4>

                    </div>

                </div>
                <iframe src={localStorage.getItem("webPath")} style={{ height: "100vh", width: "100vw", marginTop: "90px" }} />
            </div>
        )
    }
}

export default WebView;
