import React from 'react';
import '../App.css';
import backIcon from '../assets/images/back_icon.svg';
import axios from 'axios';
import AOS from 'aos';
import "aos/dist/aos.css";



class QuizResult extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            score:this.props.score,
            currentUser:localStorage.getItem('currentUser')
            

        }
        this.goBack = this.goBack.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);

    }

    goBack() {
        this.props.goBack();
    }

    componentDidMount()
    {
        let formData = new FormData();
        formData.append("email",this.state.currentUser);
        formData.append("marks",(this.state.score/5)*100)
        const url = "https://noted.venusha.com/saveMarks.php";

        axios.post(url,formData)
        .then(res=>{console.log(res.data)})
        .catch(err=>console.log(err));

    }


    render() {
        let percentage = (this.state.score/5)*100;
        return (<div className="score-container pyro">
            <h2 className="score-woo">Wooooh! You Scored</h2>
            <h1> {percentage}  %</h1>
            <button className="quiz-ans" style={{ alignSelf: "center" , borderRadius:"20px" }} onClick={this.goBack}>Back</button>

            <div class="before"></div>
            <div class="after"></div>

        </div>)


    }
}

export default QuizResult;
