import React from 'react';
import '../App.css';
import backIcon from '../assets/images/back_icon.svg';
import cardBack from '../assets/images/card_action_view.svg';
import LessonCard from '../components/LessonCard';
import axios from 'axios';
import AOS from 'aos';
import "aos/dist/aos.css";
import DarazLogo from '../assets/images/daraz-logo.png';


class CourseCheck extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],


        }

    }



    componentDidMount() {


        let formData = new FormData();

        const url = "https://noted.venusha.com/checkPro.php";
     
        formData.append("email", this.props.currentUser)
        axios.post(url, formData)
            .then(res => {
                const data = res.data;
                this.setState({ data });
            })

        this.goBack = this.goBack.bind(this);

       

    }


    goBack() {
        this.props.setHomeView();
    }
    render() {
        console.log(this.state.data)



        return (

            <div   style={{ overflowX:"hidden", height:"100vh"}} >
                <div className="fixed-top-bar"  >
                    <div className="top-bar">
                        <img src={backIcon} onClick={this.goBack}></img>
                        <h4>ICT Guru Courses</h4>

                    </div>
                </div>
                <div className="profile-container" style={{paddingLeft:"10px", textAlign:"center"}}>
                    <h4>ICT Guru Courses Pack is sold separately. </h4>
                    <h4>You can purchase it on Daraz. </h4>
                    <img src={DarazLogo} style={{ width: "30vw" }}></img>
                </div>

            </div>


        )
    }
}

export default CourseCheck;
