import React from 'react';
import '../App.css';
import backIcon from '../assets/images/back_icon.svg';
import axios from 'axios';
import AOS from 'aos';
import "aos/dist/aos.css";



class MakeQuiz extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            category: "",
            examType: "all",
            data: [],
            startBtn:"none"


        }
        this.goBack = this.goBack.bind(this);
        this.handleCategorySelect = this.handleCategorySelect.bind(this)
        this.createQuiz = this.createQuiz.bind(this)
        this.handleExamTypeSelect = this.handleExamTypeSelect.bind(this)

    }

    goBack() {
        this.props.goBack();
    }

    handleCategorySelect(event) {
        this.setState({ category: event.target.value })
        
        if (event.target.value!="all")
        {
            this.setState({startBtn:"block"})
        }

        else
        {
            this.setState({startBtn:"none"})
        }
        console.log(this.state.category)
    }
    handleExamTypeSelect(event) {
        this.setState({ examType: event.target.value })
    }

    loadQuizView() {
        this.props.loadQuizView();
    }
    createQuiz() {


        this.loadQuizView();
        localStorage.setItem('area', this.state.category);
        localStorage.setItem('exam', this.state.examType);
    }
    goBack() {
        this.props.setHomeView();
    }


    render() {

        return (
            <div  style={{ overflowX:"hidden"}}>
                <div className="fixed-top-bar"   data-aos="fade-left">
                    <div className="top-bar">
                        <img src={backIcon} onClick={this.goBack}></img>
                        <h4>Start Quiz</h4>

                    </div>

                </div>
                <div className="score-container "  >

                    <div className="select-container"  data-aos="fade-left">
                        <h4 className="quiz-title " style={{ marginBottom: "0" }}>Start your Quiz!</h4>
                        <p style={{ marginTop: "0" }}>Select Quiz Category and Exam Type. You will get questions according to your selections.</p>
                        <p>Select Category / Area</p>
                        <select name="category" category="category" className="quiz-select" value={this.state.category} onChange={this.handleCategorySelect}>
                            <option value="all">Select</option>
                            <option value="Networking">Neworking</option>
                            <option value="HTML">HTML</option>
                            <option value="Programming">Programming</option>
                            <option value="Python">Python</option>
                        </select>
                       
                        <select name="exam" category="exam" className="quiz-select" value={this.state.examType} onChange={this.handleExamTypeSelect} style={{display:"none"}}>
                            <option value="all">Select</option>

                            <option value="all">O/L</option>
                            <option value="all">A/L</option>

                        </select>
                        <button onClick={this.createQuiz} className="quiz-ans" style={{ alignSelf: "center", marginTop: "40px", borderRadius:"20px", display:this.state.startBtn }}>Next</button>
                    </div>

                </div>
            </div>)


    }
}

export default MakeQuiz;
