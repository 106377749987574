import axios from 'axios';
import react from 'react';
import '../App.css';
import doodle from '../assets/images/learn.svg';
import Alert from './Alert';



class Login extends react.Component {
    constructor(props) {
        super(props)
        this.state = {
            email: "",
            password: "",
            errorAlert: "none",
            errorAlertMsg: "",
            alertColor: ""

        }

        this.handleData = this.handleData.bind(this);
        this.loadReg = this.loadReg.bind(this);
        this.handleLogin = this.handleLogin.bind(this);
        this.onLogSuccess = this.onLogSuccess.bind(this);
    }

    handleData(event) {
        this.setState({errorAlert:"none"})
        this.setState({ [event.target.name]: event.target.value });
        console.log(this.state.email);
    }

    handleLogin(event)
    {
        event.preventDefault();
         let formData = new FormData();
         formData.append("email",this.state.email);
         formData.append("password",this.state.password);
         const url = "https://noted.venusha.com/checkUser.php";

         axios.post(url,formData)
         .then(res=> this.onLogSuccess(res.data))
         
         .catch(err=>console.log(err));
       
    }

    onLogSuccess(result)
    {  console.log(result);
        if(result=="1")
        {
            console.log(result);
            this.props.setHomeView();
            localStorage.setItem('currentUser', this.state.email);
        }
        else if(result=="0")
        {
            console.log("passwrd errroir")
            this.setState({errorAlert:"flex"});
            this.setState({errorAlertMsg:"Incorrect Email or Password. Try again."});
            this.setState({alertColor:"#ff3737"})
        }


    }

    loadReg()
    {
        this.props.setRegView();
    }

    render() {
        return (
            <div className="bg-light-blue page-container">
                <h1 className="header-text mt-20">Continue with ICT Guru</h1>
                <div >
                    <img src={doodle} className="header-image" />
                </div>
             
                <h3 className="sub-head">Login to ICT Guru</h3>
                <Alert visibility={this.state.errorAlert} alertColor={this.state.alertColor} errorAlertMsg={this.state.errorAlertMsg} />
                <div className="input-container">
                    <input type="text" className="input-text" value={this.state.email} onChange={this.handleData} name="email" id="email" placeholder="Email" />
                    <input type="password" className="input-text" value={this.state.password} onChange={this.handleData} name="password" id="password" placeholder="Password" />

                </div>
                <p className="note-text">By Logging In, your are agreeing our <strong>Privacy Policy</strong> and <strong> Terms and Conditions </strong> of use.</p>
                <button className="btn-primary mt" onClick={this.handleLogin}>Login</button>
                <p className="note-text mt">Don't have an account?</p>
                <button className="btn-secondary" onClick={this.loadReg}>Sign Up</button>
            </div>
        )
    }

}

export default Login;

