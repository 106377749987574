import React from 'react';
import '../App.css';
import backIcon from '../assets/images/back_icon.svg';
import cardBack from '../assets/images/card_action_view.svg';
import LessonCard from '../components/LessonCard';
import axios from 'axios';
import AOS from 'aos';
import "aos/dist/aos.css";


class LessonList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            searchKey: ""

        }
        this.searchState = this.searchState.bind(this);

        this.loadDoc = this.loadDoc.bind(this);
        this.goBack = this.goBack.bind(this);
    }

    testClick() {
        alert();
    }


    componentDidMount() {
        AOS.init();

        let formData = new FormData();

        const url = "https://noted.venusha.com/getLessons.php";
        axios.post(url, formData)
            .then(res => {
                const data = res.data;
                this.setState({ data });
            })



    }
    searchState(event) {
        this.setState({ searchKey: event.target.value })
        console.log(this.state.searchKey)
    }
    loadDoc() {
        this.props.setDocView();
    }

    goBack()
    {
        this.props.goBack();
    }


    render() {
        let searchResult = [];


        //for testing
        // let testRes=[];
        // let testdata = [{"id":"1","title":"Market leader in tiles,","info":null,"views":null,"author":null,"path":"https://localhost/doc.pdf"},{"id":"1","title":"Market leader in tiles,","info":null,"views":null,"author":null,"path":"Market leader in tiles,"},{"id":"1","title":"Market leader in tiles,","info":null,"views":null,"author":null,"path":"Market leader in tiles,"},{"id":"2","title":"Market leader in tiles,","info":null,"views":null,"author":null,"path":""},{"id":"3","title":"Market leader in tiles,","info":null,"views":null,"author":null,"path":""},{"id":"4","title":"html web\r\n","info":null,"views":null,"author":null,"path":""},{"id":"22","title":"bootrap web design","info":null,"views":null,"author":null,"path":""}];
        // testRes = testdata.filter(testRes => testRes.title.includes(this.state.searchKey))


        searchResult = this.state.data.filter(searchResult => searchResult.title.includes(this.state.searchKey))
        console.log(this.state.data)


        return (

            <div >
                <div className="fixed-top-bar" style={{ backgroundColor: "#ffffff", position:"fixed" }}>
                    <div className="top-bar">
                        <img src={backIcon} onClick={this.goBack}></img>
                        <h4>ICT Guru HUB</h4>

                    </div>
                    <div className="search-container"  >
                        <input type="text" className="search-bar" value={this.state.searchKey} onChange={this.searchState} placeholder="Search..." />
                    </div>
                </div>

                <div className="lesson-card-container" style={{ paddingTop: "150px" }}>
                    {
                        searchResult.map(item => (
                            <LessonCard key={item.id} title={item.title} path={item.path} loadDoc={this.loadDoc} />
                        ))}


                </div>
            </div>
        )
    }
}

export default LessonList;
