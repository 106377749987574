import React from 'react';
import '../App.css';
import backIcon from '../assets/images/back_icon.svg';
import axios from 'axios';
import AOS from 'aos';
import "aos/dist/aos.css";
import profileIcon from '../assets/images/profile.svg';
import logo from '../assets/images/logo.png';



class About extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
     
        }
        this.goBack = this.goBack.bind(this);

    }
    goBack() {
        this.props.setHomeView();
    }

    render() {
       
        return ( 
            <div   style={{ overflowX:"hidden", height:"100vh"}} >
            <div className="fixed-top-bar"  >
                <div className="top-bar">
                    <img src={backIcon} onClick={this.goBack}></img>
                    <h4>About App</h4>

                </div>
            </div>
            <div className="profile-container" style={{paddingLeft:"10px", textAlign:"center"}}>
                <img src={logo} style={{width:"40%"}}></img>
                <h4>ICT Guru - #1 ICT Learning App in Sri Lanka</h4>
                <p>Version - 10.5</p>
                <p>All Rights Resereved. www.ictguru.lk</p>
                
            </div>

        </div>
            )


    }
}

export default About;
