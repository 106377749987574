import React from 'react';
import '../App.css';
import backIcon from '../assets/images/back_icon.svg';
import cardBack from '../assets/images/card_action_view.svg';
import AOS from 'aos';
import "aos/dist/aos.css";


class LessonList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentUser: "",
           
        }
        this.loadDoc = this.loadDoc.bind(this);
    }

    loadDoc()
    {
        this.props.loadDoc();
        localStorage.setItem('currentDocTitle', this.props.title);
        localStorage.setItem('currentDocPath',this.props.path);
    }

    componentDidMount()
    {
        AOS.init();
    }


  
    render() {
        return (
          
                    <div className="lesson-card" onClick={this.loadDoc}>
                        <h4>{this.props.title} </h4>
                        <img src={cardBack}></img>
                        
                    </div>

                   
        )
    }
}

export default LessonList;
