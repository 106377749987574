import React from 'react';
import '../App.css';
import backIcon from '../assets/images/back_icon.svg';
import axios from 'axios';
import AOS from 'aos';
import "aos/dist/aos.css";
import profileIcon from '../assets/images/profile.svg';



class Modal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
           
          
            

        }
        this.negativeAction = this.negativeAction.bind(this);
        this.positiveAction = this.positiveAction.bind(this);
     

    }

 positiveAction()
 {
    this.props.positiveAction();
 }

 negativeAction()
 {
    this.props.negativeAction();
 }




    render() {
       
        return (<div className="modal" style={{display:this.props.visibility}}>
       <h4>Are Sure You want to change your existing password? {this.props.title}</h4>
 <button onClick={this.positiveAction}>Yes</button>
 <button onClick={this.negativeAction}>No</button>
        </div>)


    }
}

export default Modal;
