import React from 'react';
import '../App.css';
import backIcon from '../assets/images/back_icon.svg';
import axios from 'axios';
import AOS from 'aos';
import "aos/dist/aos.css";
import profileIcon from '../assets/images/profile.svg';
import CloseButton from '../assets/images/close.svg';
import PrivacyPolicy from '../assets/docs/pp.htm';
import TOS from '../assets/docs/tos.htm'



class Menu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        this.close = this.close.bind(this)
        this.setWebViewPath = this.setWebViewPath.bind(this);
        this.setTOSPath = this.setTOSPath.bind(this)
  
    }

    close()
    {
        this.props.closeMenu()
    }

    setWebViewPath()
    {
        localStorage.setItem("webPath",PrivacyPolicy)
        this.props.loadWebView()
    }

    setTOSPath()
    {
        localStorage.setItem("webPath",TOS)
        this.props.loadWebView()
    }

    render() {
       
        return ( <div className="menu slideOut" style={{position:"fixed", height:"100%", display:this.props.visibility}} data-aos="fade-right" id="slide">
        <div className="menu-header">  <img src={CloseButton} onClick={this.close} style={{width:"30px"}}></img></div>
        <h4>About ICT Guru</h4>
        <button className="menu-item" onClick={this.props.setAboutView}>About App</button>
        <button className="menu-item"  onClick={this.setWebViewPath}>Privacy Policy</button>
        <button className="menu-item" onClick={this.setTOSPath}>Terms and Conditions</button>
        
        <br></br>
        <h4>My Account</h4>
        <button className="menu-item" onClick={this.props.setProfileView}>Manage My Account</button>
        <button className="menu-item" onClick={this.props.setNotificationView}>Notifications</button>
        <button className="menu-item" onClick={this.props.setLogView}>Logout</button>
        <br></br>
        <h4>Help and Support</h4>
        
    <a href="https://ictguru.lk">    <button className="menu-item">Connect with ICT Guru</button></a>
        
      
        </div>)


    }
}

export default Menu;
