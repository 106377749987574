import React from 'react';
import '../App.css';
import backIcon from '../assets/images/back_icon.svg';
import axios from 'axios';
import AOS from 'aos';
import "aos/dist/aos.css";
import QuizResult from './QuizResult';


class Quiz extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentUser: "",
            data: [],
            score: 0,
            currentQuestionCount: 0,
            currentQuestion: [],
            selectedAnswer: 0,
            currentCorrectAnser: "",
            length: 0,
            QuizEnd: 0,
            quizStart: 0,
            timeCountdown: 25,
            quizDuration: 25000,

        }
        this.goBack = this.goBack.bind(this)
        this.checkAnswer = this.checkAnswer.bind(this)
        this.countdown = this.countdown.bind(this)
        this.timer = this.timer.bind(this)




    }
    goBack() {
        this.props.goBack();
    }

    componentDidMount() {
        AOS.init();

      //  console.log(localStorage.getItem('exam'))
       // console.log(localStorage.getItem('area'))

        let formData = new FormData();
        formData.append("area", localStorage.getItem('area'));
        
        const url = "https://noted.venusha.com/getQuestions.php";
        axios.post(url, formData)
            .then(res => {
                const data = res.data;
                this.setState({ data })
                console.log(this.state.data)

                this.setState({ length: data.length })


            })
        this.timer();
        //   this.startQuiz();
        console.log(this.state.data)

    }

    timer() {

        setTimeout(setInterval(this.countdown, 1000), this.state.quizDuration)
    }

    startQuiz() {

    }


    countdown() {

        if (this.state.timeCountdown > 0) {
            this.setState({ timeCountdown: this.state.timeCountdown - 1 })


        }
        else {
            this.setState({ QuizEnd: 1 })
        }

    }
    checkAnswer(ans, correctAns) {

        if (this.state.QuizEnd == 0) {

            if (this.state.length > 0) {
                this.setState({ currentQuestionCount: this.state.currentQuestionCount + 1 })
                this.setState({ currentQuestion: this.state.data[this.state.currentQuestionCount] })
                console.log(this.state.currentQuestionCount)
                console.log("selected answer :" + ans)
                console.log("Correct Answer" + correctAns)

                if (ans == correctAns) {
                    this.setState({ score: this.state.score + 1 });
                    console.log("Score" + this.state.score);
                }
                else {
                    console.log("Incorrect");
                }

            }

            else {
                this.setState({ QuizEnd: 1 })
            }
        }
        else {
            alert("times up")
            clearInterval(this.timer);
        }

        this.setState({ length: this.state.length - 1 })
        console.log("length " + this.state.length)

    }






    render() {
        let questions = [] = this.state.data;
        let list = this.state.currentQuestion;

      
        let QNo = "";


        if (this.state.currentQuestionCount == 0) {
            QNo = "Time is running! Tap one these bars to start."
        }
        else {
            QNo = "Question " + this.state.currentQuestionCount;

        }



        if (this.state.QuizEnd == 0) {
            return (

                <div>





                    <div className="quiz-container" >

                        <div className="quiz-container" >
                            <h2 className="quiz-number" >{this.state.timeCountdown}</h2>
                            <h2 className="quiz-number">{QNo}</h2>
                            <h4 className="quiz-title fade-in-text " >{list.title}</h4>
                            <button className="quiz-ans" onClick={() => this.checkAnswer(list.ans1, list.correctAns)}>{list.ans1}</button>
                            <button className="quiz-ans" onClick={() => this.checkAnswer(list.ans2, list.correctAns)}>{list.ans2}</button>
                            <button className="quiz-ans" onClick={() => this.checkAnswer(list.ans3, list.correctAns)}>{list.ans3}</button>
                            <button className="quiz-ans" onClick={() => this.checkAnswer(list.ans4, list.correctAns)}>{list.ans4}</button>

                        </div>




                    </div>

                </div>
            )
        }

        else {
            return (<QuizResult score={this.state.score} goBack={this.goBack} />)

        }

    }
}

export default Quiz;
