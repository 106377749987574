import React from 'react';
import '../App.css';
import backIcon from '../assets/images/back_icon.svg';
import cardBack from '../assets/images/card_action_view.svg';
import LessonCard from '../components/LessonCard';
import axios from 'axios';
import AOS from 'aos';
import "aos/dist/aos.css";


class Notification extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            searchKey: ""

        }
        this.searchState = this.searchState.bind(this);

        this.loadDoc = this.loadDoc.bind(this);
        this.goBack = this.goBack.bind(this);
    }

    testClick() {
        alert();
    }


    componentDidMount() {
        AOS.init();

        let formData = new FormData();

        const url = "https://noted.venusha.com/getNotifications.php";
        axios.post(url, formData)
            .then(res => {
                const data = res.data;
                this.setState({ data });
            })



    }
    searchState(event) {
        this.setState({ searchKey: event.target.value })
        console.log(this.state.searchKey)
    }
    loadDoc() {
        this.props.setDocView();
    }

    goBack()
    {
        this.props.setHomeView();
    }


    render() {
        let searchResult = [];


        //for testing
        // let testRes=[];
        // let testdata = [{"id":"1","title":"Market leader in tiles,","info":null,"views":null,"author":null,"path":"https://localhost/doc.pdf"},{"id":"1","title":"Market leader in tiles,","info":null,"views":null,"author":null,"path":"Market leader in tiles,"},{"id":"1","title":"Market leader in tiles,","info":null,"views":null,"author":null,"path":"Market leader in tiles,"},{"id":"2","title":"Market leader in tiles,","info":null,"views":null,"author":null,"path":""},{"id":"3","title":"Market leader in tiles,","info":null,"views":null,"author":null,"path":""},{"id":"4","title":"html web\r\n","info":null,"views":null,"author":null,"path":""},{"id":"22","title":"bootrap web design","info":null,"views":null,"author":null,"path":""}];
        // testRes = testdata.filter(testRes => testRes.title.includes(this.state.searchKey))


        searchResult = this.state.data;
        


        return (

            <div>
                <div className="fixed-top-bar" style={{ backgroundColor: "#ffffff" , position:"fixed"}}>
                    <div className="top-bar">
                        <img src={backIcon} onClick={this.goBack}></img>
                        <h4>Notifications</h4>
                    </div>
                
                </div>

                <div className="lesson-card-container" data-aos="zoom-in-down" style={{ paddingTop: "100px" }}>
                    {
                        searchResult.map(item => (
                            <div className="notification" key={item.id} title={item.title} path={item.path} loadDoc={this.loadDoc} >
                                <p>{item.body}</p>  <p>{item.date}</p> </div>
                        ))}


                </div>
            </div>
        )
    }
}

export default Notification;
